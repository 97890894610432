
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import QuotesService from "@/core/services/car/QuotesService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { roles } from "@/store/stateless/store";

// import Multiselect from "@vueform/multiselect";
import LeadDriverDetailsView from "@/components/partials/leaddriverdetails/Lead-DriverDetiails-View.vue";

interface PolicyData {
  companyId: any;
  repair: any;
  value: any;
  discount: any;
}

export default defineComponent({
  name: "generate-driver-details-quote-pdf",

  components: { LeadDriverDetailsView },

  setup() {
    const store = useStore();
    const route = useRoute();
    let policyTypeValue = ref<string>("0");
    let key = ref<any>();
    let policyData = ref<Array<PolicyData>>([
      {
        companyId: "",
        repair: "",
        value: "",
        discount: "",
      },
      {
        companyId: "",
        repair: "",
        value: "",
        discount: "",
      },
      {
        companyId: "",
        repair: "",
        value: "",
        discount: "",
      },
    ]);

    let companiesList = computed(() => {
      return store.getters.getInsuranceCompaniesList;
    });

    let companyList = ref({
      mode: "tags",
      data: [],
      options: companiesList,
      searchable: true,
      createTag: true,
    });

    onMounted(() => {
      if(!roles.customerdetails.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Generate Quote PDF", ["Leads & Tasks"]);
    });
    Promise.all([
      QuotesService.fetchQuote({
        driver_details_id: route.params.driverDetailsId,
      }),
    ]).then((data) => {
      
      console.log("afer fetching");
      console.log(editQuoteObj.value);
      
      key.value = editQuoteObj.value.driver_details_id;
    });

    const search = ref<string>("");
    const searchCustomers = () => {
      console.log(search.value);
    };

    let editQuoteObj = computed(() => {
      return store.getters.getEditQuote;
    });
    let editDomStatus = computed(() => {
      return store.getters.getShowEditDriverDetailsStatus;
    });
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });
    let getDuplicateDataExist = computed(() => {
      return store.getters.getDuplicateQuotesErrorMessage;
    });

    let getIsManualQuoteLoading = computed(() => {
      return store.getters.getManaulOrderLoading;
    });
    return {
      getIsManualQuoteLoading,
      // errorMessage,
      // value,
      roles,
      editQuoteObj,
      editDomStatus,
    };
  },
});
